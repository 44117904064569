var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "lg", opacity: "1" } },
        [
          _vm.rows.length > 0
            ? _c(
                "v-list",
                { attrs: { "three-line": "" } },
                [
                  _vm._l(_vm.rows, function (item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: item.Title, staticClass: "px-0 py-0 mt-2" },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "px-5 py-0" },
                            [
                              _c("p", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.Notification.Subject) +
                                    "   "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-muted font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("ago")(
                                            item.Notification.PlannedSendDate
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "mt-1 font-weight-light" },
                                [_vm._v(_vm._s(item.Notification.Body))]
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-action",
                            { staticClass: "pr-5" },
                            [
                              !item.IsRead
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        loading: _vm.loadingSave,
                                        fab: "",
                                        small: "",
                                        color: "grey lighten-1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.readNotification(item)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-email")])],
                                    1
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        loading: _vm.loadingSave,
                                        fab: "",
                                        small: "",
                                        color: "grey lighten-1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.readNotification(item)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-email-open")])],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      index != _vm.rows.length - 1
                        ? _c("v-divider", {
                            key: index,
                            staticClass: "my-0",
                            attrs: { inset: item.inset },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _c("v-card-text", { staticClass: "text-center" }, [
                _vm._v(" Bildirim yok "),
              ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "v-btn",
            {
              attrs: { fab: "", icon: "", disabled: _vm.options.page == 1 },
              on: {
                click: function ($event) {
                  _vm.options.page -= 1
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left-bold-circle")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                icon: "",
                disabled: _vm.rows.length != _vm.options.rowsPerPage,
              },
              on: {
                click: function ($event) {
                  _vm.options.page += 1
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-right-bold-circle")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }