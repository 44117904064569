<template>
  <div>
    <b-overlay :show="loading" rounded="lg" opacity="1">
      <v-list v-if="rows.length > 0" three-line>
        <template v-for="(item, index) in rows">
          <v-list-item class="px-0 py-0 mt-2" :key="item.Title">
            <v-list-item-content class="px-5 py-0">
              <p class="font-weight-bold">
                {{ item.Notification.Subject }} &nbsp;
                <span class="text-muted font-weight-bold">
                  {{ item.Notification.PlannedSendDate | ago }}
                </span>
              </p>
              <p class="mt-1 font-weight-light">{{ item.Notification.Body }}</p>
            </v-list-item-content>
            <v-list-item-action class="pr-5">
              <v-btn
                v-if="!item.IsRead"
                :loading="loadingSave"
                @click="readNotification(item)"
                fab
                small
                color="grey lighten-1"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <v-btn
                v-else
                :loading="loadingSave"
                @click="readNotification(item)"
                fab
                small
                color="grey lighten-1"
              >
                <v-icon>mdi-email-open</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            class="my-0"
            v-if="index != rows.length - 1"
            :key="index"
            :inset="item.inset"
          ></v-divider>
        </template>
      </v-list>
      <v-card-text v-else class="text-center"> Bildirim yok </v-card-text>
    </b-overlay>
    <div class="text-right">
      <v-btn fab icon :disabled="options.page == 1" @click="options.page -= 1">
        <v-icon>mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-btn fab icon :disabled="rows.length != options.rowsPerPage" @click="options.page += 1">
        <v-icon>mdi-arrow-right-bold-circle</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  name: 'my-announcements',
  data() {
    return {
      totalRows: 0,
      rows: [],
      loading: true,
      loadingSave: false,
      options: {
        page: 1,
        rowsPerPage: 4
      }
    }
  },
  filters: {
    ago: function (date) {
      return moment(date).locale('tr').fromNow()
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    readNotification(item) {
      this.loadingSave = true
      ApiService.setHeader()
      ApiService.put('api/MyNotificationUser/read', { Id: item.Id, IsRead: !item.IsRead })
        .then(() => {
          this.loadingSave = false
          ApiService.showSaveSuccess()
          this.getDataFromApi()
        })
        .catch(({ response }) => {
          this.loadingSave = false
          ApiService.showError(response)
        })
    },
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/MyNotificationUser/announcement', {
        rowsPerPage: this.options.rowsPerPage ?? 10,
        page: this.options.page ?? 1
      })
        .then((data) => {
          this.rows = data.data
          this.loading = false

          for (let i = 0; i < this.rows.length; i++) {
            const element = this.rows[i].Notification
            if (element.Data) element.Data = JSON.parse(element.Data)
          }
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
