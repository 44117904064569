var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { dark: "", grow: "", "background-color": "indigo" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab", { attrs: { href: "#tab-1" } }, [
                _vm._v(" BİLDİRİMLER"),
              ]),
              _c("v-tab", { attrs: { href: "#tab-2" } }, [
                _vm._v(" DUYURULAR"),
              ]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              attrs: { touchless: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "tab-1" } },
                [_c("MyNotifications")],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-2" } },
                [_c("MyAnnouncements")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }