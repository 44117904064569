<template>
  <div>
    <b-overlay :show="loading" rounded="lg" opacity="1">
      <v-list v-if="rows.length > 0" three-line>
        <template v-for="(item, index) in rows">
          <v-list-item class="px-0 mt-2" :key="item.Title">
            <v-list-item-content class="pl-5">
              <p class="font-weight-bold">
                {{ item.Notification.Subject }} &nbsp;
              </p>
              <p v-if="item.Notification.Link == null" class="mt-1 font-weight-light" v-html='item.Notification.Body'></p>
              <a v-else class="mt-3 font-weight-light text-break" v-html="item.Notification.Body" @click="openLink(item.Notification.Link)"></a>
              <br/>
              <span class="text-muted font-weight-bold mt-5 text-left">
                  {{ item.Notification.PlannedSendDate | ago }}
                </span>
            </v-list-item-content>
            <v-list-item-action class="pr-5">
              <v-btn
                  v-if="item.Notification.Path != null"
                  icon
                  text
                  small
                  :to="{path:  item.Notification.Path}"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
              class="mx-4"
              v-if="index != rows.length - 1"
              :key="index"
          ></v-divider>
        </template>
      </v-list>
      <v-card-text v-else class="text-center"> Bildirim yok</v-card-text>
    </b-overlay>
    <div class="text-right">
      <v-btn fab icon :disabled="options.page == 1" @click="options.page -= 1">
        <v-icon>mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-btn fab icon :disabled="rows.length != options.rowsPerPage" @click="options.page += 1">
        <v-icon>mdi-arrow-right-bold-circle</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  name: 'my-notifications',
  data() {
    return {
      rows: [],
      loading: true,
      loadingSave: false,
      options: {
        page: 1,
        rowsPerPage: 4
      }
    }
  },
  filters: {
    ago: function (date) {
      return moment(date).locale('tr').fromNow()
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/MyNotificationUser/pushnotification', {
        rowsPerPage: this.options.rowsPerPage ?? 10,
        page: this.options.page ?? 1
      })
          .then((data) => {
            this.$nextTick(() => {
              this.rows = data.data
              this.loading = false

              for (let i = 0; i < this.rows.length; i++) {
                const element = this.rows[i].Notification
                if (element.Data) element.Data = JSON.parse(element.Data)
              }
            })
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    openLink(link) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
}
</script>
