<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" dark grow background-color="indigo">
        <v-tab href="#tab-1"> BİLDİRİMLER</v-tab>
        <v-tab href="#tab-2"> DUYURULAR</v-tab>
      </v-tabs>
      <v-tabs-items touchless v-model="tab">
        <v-tab-item :value="'tab-1'">
          <MyNotifications />
        </v-tab-item>
        <v-tab-item :value="'tab-2'">
          <MyAnnouncements />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import MyNotifications from '@/view/pages/notification/MyNotifications.vue'
import MyAnnouncements from '@/view/pages/notification/MyAnnouncements.vue'

export default {
  name: 'notifications-page',
  components: {
    MyNotifications,
    MyAnnouncements
  },
  data() {
    return { tab: null }
  }
}
</script>
