var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "lg", opacity: "1" } },
        [
          _vm.rows.length > 0
            ? _c(
                "v-list",
                { attrs: { "three-line": "" } },
                [
                  _vm._l(_vm.rows, function (item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: item.Title, staticClass: "px-0 mt-2" },
                        [
                          _c("v-list-item-content", { staticClass: "pl-5" }, [
                            _c("p", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                " " + _vm._s(item.Notification.Subject) + "   "
                              ),
                            ]),
                            item.Notification.Link == null
                              ? _c("p", {
                                  staticClass: "mt-1 font-weight-light",
                                  domProps: {
                                    innerHTML: _vm._s(item.Notification.Body),
                                  },
                                })
                              : _c("a", {
                                  staticClass:
                                    "mt-3 font-weight-light text-break",
                                  domProps: {
                                    innerHTML: _vm._s(item.Notification.Body),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openLink(
                                        item.Notification.Link
                                      )
                                    },
                                  },
                                }),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-muted font-weight-bold mt-5 text-left",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("ago")(
                                        item.Notification.PlannedSendDate
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-list-item-action",
                            { staticClass: "pr-5" },
                            [
                              item.Notification.Path != null
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        text: "",
                                        small: "",
                                        to: { path: item.Notification.Path },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      index != _vm.rows.length - 1
                        ? _c("v-divider", { key: index, staticClass: "mx-4" })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _c("v-card-text", { staticClass: "text-center" }, [
                _vm._v(" Bildirim yok"),
              ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "v-btn",
            {
              attrs: { fab: "", icon: "", disabled: _vm.options.page == 1 },
              on: {
                click: function ($event) {
                  _vm.options.page -= 1
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left-bold-circle")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                icon: "",
                disabled: _vm.rows.length != _vm.options.rowsPerPage,
              },
              on: {
                click: function ($event) {
                  _vm.options.page += 1
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-right-bold-circle")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }